import { useEffect, useRef } from 'react';
import imgChat from '../../img/chat.png';

const VoiceFlowChat = () => {
  const workspace = sessionStorage.getItem('workspace');
  const scriptRef = useRef(null);

  const styleElement = document.createElement('style');
  styleElement.textContent = `
      .blinking-img {
          width: 56px !important;
          height: 56px !important;
          animation: blink-animation 1s steps(5, start) infinite;
      }

      @keyframes blink-animation {
          to {
              visibility: hidden;
          }
      }
  `;

  const waitForElement = (selector, parent, timeout = 8000) => new Promise((resolve, reject) => {
    const startTime = Date.now();

    const checkElement = () => {
      const element = parent.querySelector(selector);
      if (element) {
        resolve(element);
      } else if (Date.now() - startTime >= timeout) {
        reject(new Error(`Timeout exceeded while waiting for element with selector "${selector}"`));
      } else {
        setTimeout(checkElement, 100); // Revisar cada 100 ms
      }
    };

    checkElement();
  });

  const searchVoiceFlowChatDiv1 = (element) => {
    if (element) {
      const { shadowRoot } = element;
      if (shadowRoot) {
        waitForElement('img', shadowRoot).then((imgElement) => {
          if (imgElement) {
            const newImage = new Image();
            newImage.src = imgChat;
            newImage.onload = () => {
              // imgElement.src = newImage.src;
              imgElement.className = 'blinking-img';
            };
          }
        });
        shadowRoot.appendChild(styleElement);
      }
    }
  };

  useEffect(() => {
    const addScript = () => {
      if (!scriptRef.current) {
        const script = document.createElement('script');
        script.onload = () => {
          window.voiceflow.chat.load({
            verify: { projectID: '66df5b0d69870fb5788c66c7' },
            url: 'https://general-runtime.voiceflow.com',
            versionID: 'production'
          });
        };
        script.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
        script.type = 'text/javascript';
        document.head.appendChild(script);
        scriptRef.current = script;

        waitForElement('#voiceflow-chat', document).then(searchVoiceFlowChatDiv1);
        // window.addEventListener('DOMContentLoaded', searchVoiceFlowChatDiv);
      }
    };

    const removeScript = () => {
      const voiceFlowChat = document.getElementById('voiceflow-chat');
      if (scriptRef.current && voiceFlowChat) {
        scriptRef.current.parentNode.removeChild(scriptRef.current);
        scriptRef.current = null;
        voiceFlowChat.parentNode.removeChild(voiceFlowChat);
      }
    };

    if (!workspace) {
      addScript();
    } else {
      removeScript();
    }

    return () => {
      if (!workspace) removeScript();
    };
  }, [workspace]);

  return null;
};

export default VoiceFlowChat;
